<template>
  <div class="poster-container">
      <div class="video-player-container" 
      id="touch-to-swipe"
      @touchstart="onTouchStart"
      @touchmove="onTouchMove"
      @touchend="onTouchEnd">
      <div class="content-align">
        <div class="video-player-carousel">
            <video id="video-short" @click="actPlayback" :poster="getPosterFileurl" autoplay></video>
            <img class="nextcarouselimg" :src="getPosterFileurlNext" alt="img"/>
          </div>
      
          <button class="play-button" v-if="!isPaused && autoHide" @click="actPlayback" >
            <img src="@/assets/icons/Sainaplaynow.svg" alt="play">
          </button>
          <button class="play-button pause" v-if="isPaused && autoHide" @click="actPlayback" >
            <img src="@/assets/icons/shortPause.svg" alt="pause">
          </button>
          <div class="watch-now-container">
          <p v-if="currentReel">{{ currentReel.title }}</p>
          <button class="watch-now-button" @click="(event)=> playMovie(currentReel.parentid)" v-if="currentReel && currentReel.parentid">{{$t("WATCH NOW")}}</button>
        </div>
      </div>

          <div class="button-container">
            <div class="shorts-icon"> 
              <button :class="['like-button', liked == true ? 'active-action-short': {} ]"  :disabled="!likeCountLoaded"  @click="updateLikes">
                <img src="@/assets/icons/Shortslike.svg" alt="Like">
              </button>
              <div :style="isNaN(contentLikeCount) ? 'margin-left: 0.4rem' : 'margin-left: 0.8rem;'">{{contentLikeCount == 0 ? $t('Like') : $t(contentLikeCount)}}</div>
            </div>
            <div class="shorts-icon"> 
              <button  :class="['dislike-button', (liked == false && !removeDislikeStatus) ? 'active-action-short': {} ]" :disabled="!dislikeCountLoaded" @click="updateDisLikes">
                <img src="@/assets/icons/ShortsDislike.svg" alt="Dislike">
              </button>
              <div :style=" 'margin-left: 0.4rem' ">{{$t(dislikeCountDisplayNew)}}</div>
            </div>

            <div class="shorts-icon"> 
              <button class="share-button" @click="() => shareMovie(true)">
                <img src="@/assets/icons/Shortsshare.svg" alt="Share">
              </button>
              <div style="margin-left: 0.4rem;">{{ $t('Share') }}</div>
            </div>
        
            <detailShare :content="currentReel" v-if="isShareActive"  class="shortpage-share" :closePopup="() => closePopup(false)"></detailShare>
        </div>
        

       

      </div>
   
  </div>
</template>

<script>
import { store } from "@/store/store";
import firebase from "firebase/app";
import "firebase/database";
import { mapGetters } from "vuex";
import FirebaseActions from "@/mixins/FirebaseActions.js";
import { eventBus } from "@/eventBus";

export default {
  mixins: [FirebaseActions],
  watch: {
    subscriberid(val) {
      this.subscriberidLocal = val;
    }
  }, 
  data () {
    return {
      startX: 0,
      startY: 0,
      threshold: 50,
      localDisplayLang: '',
      shortReelLists: [],
      currentReelIndex: 0,
      hls: null,
      videoPlayer: null,
      isPaused: false,
      getPosterFileurl: '',
      getPosterFileurlNext: '',
      currentReel: {},
      currentState: '',
      action: '',
      dislikeCountDisplay: 'Dislike', 
      dislikeCountDisplayNew: '',
      contentLikeCount: 'Like',
      actionTwo: null,
      liked: null,
      isShareActive: null,
      autoHide: true,
      subscriberidLocal: null,
      removeDislikeStatus: false,
      likeCountLoaded: false,
      dislikeCountLoaded: false,
    }
  },
  methods: {
    playerInvocked (reel) {
   
      if (window.Hls.isSupported()) {
 
        this.hls.loadSource(reel);
        this.videoPlayer.play();
      } else {
        this.videoPlayer.src = reel;
        this.videoPlayer.addEventListener('loadedmetadata', function() {
          this.videoPlayer.play();
          });
          
      }

    },
    onTouchStart(event) {
      this.startX = event.touches[0].clientX;
      this.startY = event.touches[0].clientY;
    },
    onTouchMove(event) {
  // Calculate the distance moved
  const currentX = event.touches[0].clientX;
  const currentY = event.touches[0].clientY;
  const deltaX = currentX - this.startX;
  const deltaY = currentY - this.startY;

  // Check if the swipe is vertical and meets the threshold
  if (Math.abs(deltaY) > Math.abs(deltaX) && Math.abs(deltaY) > 100) {
    if (deltaY > 0) {
      if (this.currentReelIndex == 0) return
          // this.hls && this.hls.destroy();

          // this.hls.attachMedia(this.videoPlayer);


          let reel = this.getTheStream(this.shortReelLists[--this.currentReelIndex].contentdetails);
          this.getPoster();
          this.currentReel = this.shortReelLists[this.currentReelIndex]
          this.initFirebaseLike()

          // this.hls.loadSource(reel);
          // this.videoPlayer.play();
          this.playerInvocked(reel)
    } else {
      if (this.shortReelLists.length - 1 == this.currentReelIndex) return;
        
        let reel = this.getTheStream(this.shortReelLists[++this.currentReelIndex].contentdetails);
      
        this.getPoster();
        this.currentReel = this.shortReelLists[this.currentReelIndex]
        // this.hls.loadSource(reel);
        // this.videoPlayer.play();
        this.playerInvocked(reel)
        this.initFirebaseLike()
    }
  }
}
,

    onTouchEnd() {
      this.startX = 0;
      this.startY = 0;
    },
  
    playMovie (contentid) {
      
      let payload = {
        contentid: contentid,
        params: {
          displaylanguage: this.localDisplayLang,
        },
      };
      store
        .dispatch("contentDetail", payload)
        .then((response) => {
          if (!response.data.reason) {
            console.error(response.data)
            let currentLanguage = this.getCurrentLanguageUrlBase();

            let content = response.data
      
                if(content.category == "TVSHOW") {
                let title = this.spacialCharEncoding(content.defaulttitle || content.title);
                this.$router.push({ name: "detailPage", params: { contentId: content.objectid.toLowerCase() , mediaTitle:title,lang: currentLanguage   } });

              } else if ( content.category == 'SHORTS') {
                let title = this.spacialCharEncoding(content.defaulttitle || content.title);
                this.$router.push({
                  name: "sainashorts",
                  params: { contentId: content.objectid.toLowerCase(), mediaTitle: title, lang: currentLanguage }
                });
              } else {
              let title = this.spacialCharEncoding(content.defaulttitle || content.title);
                this.$router.push({ name: "detailPageMovie", params: { mediaTitle:title   , contentId: content.objectid.toLowerCase() ,lang: currentLanguage  } });
              }
        
          } 
        })
        .catch((error) => console.log(error));
    },
    initPlayerListener () {
      this.videoPlayer = document.getElementById('video-short');
      this.hls = new window.Hls();
      this.hls.on(window.Hls.Events.MEDIA_ATTACHED, function () {
        console.log('video and hls.js are now bound together !');
      });
      this.hls.on(window.Hls.Events.MANIFEST_PARSED, function (event, data) {
        console.log(
          'manifest loaded, found ' + data.levels.length + ' quality level'
        );
      });

      this.hls.attachMedia(this.videoPlayer);
    },
    updateLikes(){
      // this.action = "ADD"
            if (this.subscriberidLocal) {
                 if (this.action === "ADD" && this.currentState === "") {
                    this.updateContentCount(
            this.currentReel,
            this.contentLikeCount,
            "LIKE"
          );
        
          setTimeout(() => { 
            this.updateLikeDislikeToFire(this.currentReel, "LIKE");
          }, 500)
          this.contentLikeCount = this.contentLikeCount + 1;
        } 
        // else if (this.action === "ADD" && this.currentState === "DISLIKE") {
        //   // when the content is DISLIKE change the status and count
        //   console.log("dislikeCountDisplay", this.dislikeCountDisplay);
        //   this.updateContentCount(
        //     this.currentReel,
        //     this.contentLikeCount,
        //     "LIKE",
        //     this.dislikeCountDisplay
        //   );
        //   this.updateLikeDislikeToFire(this.currentReel, "LIKE");
        //   this.contentLikeCount = this.contentLikeCount + 1;
        //   this.dislikeCountDisplay =
        //   this.dislikeCountDisplay > 0 ? this.dislikeCountDisplay - 1 : 0;
        // }
         else if (this.action === "REMOVE") {
          // console.log("remove");
          this.updateContentCount(
            this.currentReel,
            this.contentLikeCount,
            "REMOVELIKE"
          );
          setTimeout(() => {
            this.updateLikeDislikeToFire(this.currentReel, "NONE");

          }, 500)
          //calculate counts
          this.contentLikeCount =
          this.contentLikeCount > 0 ? this.contentLikeCount - 1 : 0;
        } else {
          this.updateContentCount(
            this.currentReel,
            this.contentLikeCount,
            "LIKE"
          );
          setTimeout(() => { 
            this.updateLikeDislikeToFire(this.currentReel, "LIKE");
          }, 500)
          this.contentLikeCount = this.contentLikeCount + 1;
        }
            } else {
        eventBus.$emit("toggle-auth", true); 
            }
        },
    fetchButtonStateForDisLike() {
     if (!this.currentReel) return;
      let content = this.currentReel;
      let path = `/subscriber/${this.subscriberidLocal}/${this.profileid}/content/`;
      if ("seriesid" in content && content.seriesid) {
        path += `${content.objectid}/episodes/${content.objectid}`;
      } else {
        path += `${content.objectid}`;
      }
      firebase
        .database()
        .ref(path)
        .on("value", (snapshot) => {
          this.removeDislikeStatus = false;
          let response = snapshot.val();
          if (!response) {
            this.contentLikeCount = 0;
          }
          if (response && response.likestatus === "DISLIKE") {
            this.likedTwo = true;
            this.actionTwo = "REMOVE";
            this.currentStateTwo = "";
          } else if (response && response.likestatus === "LIKE") {
            this.actionTwo = "ADD";
            this.likedTwo = false;
            this.currentStateTwo = "LIKE";
          } else {
            this.likedTwo = false;
            this.actionTwo = "ADD";
            this.currentStateTwo = "";
            if (response.likestatus === 'NONE') {
              this.removeDislikeStatus = true;
            }
          }
          // console.log("action", this.action);
        });
    },
    updateDisLikes() {
      if (this.subscriberidLocal) {
        if (this.actionTwo === "ADD" && this.currentStateTwo === "") {
          this.updateContentCount(
            this.currentReel,
            this.dislikeCountDisplay,
            "DISLIKE"
          );
          setTimeout(() => { 
            this.updateLikeDislikeToFire(this.currentReel, "DISLIKE");
          }, 500)
       
          //calculate counts
          this.dislikeCountDisplay = this.dislikeCountDisplay + 1;
        } else if (this.actionTwo === "ADD" && this.currentStateTwo === "LIKE") {
          console.log("contentLikeCount", this.contentLikeCount);
          this.updateContentCount(
            this.currentReel,
            this.dislikeCountDisplay,
            "DISLIKE",
            this.contentLikeCount
          );
          setTimeout(() => { 
            this.updateLikeDislikeToFire(this.currentReel, "DISLIKE");
          }, 500)
        
          this.dislikeCountDisplay = this.dislikeCountDisplay + 1;
          this.contentLikeCount =
          this.contentLikeCount > 0 ? this.contentLikeCount - 1 : 0;
        } else if (this.actionTwo === "REMOVE") {
          this.updateContentCount(
            this.currentReel,
            this.dislikeCountDisplay,
            "REMOVEDISLIKE"
          );
          setTimeout(() => { 
            this.updateLikeDislikeToFire(this.currentReel, "NONE");
          }, 500)
          
          this.dislikeCountDisplay =
          this.dislikeCountDisplay > 0 ? this.dislikeCountDisplay - 1 : 0;
        }
      } else {
        eventBus.$emit("toggle-auth", true); 
      }
    },
        fetchButtonState() {
      if (!this.currentReel || !this.profileid) return;
      let content = this.currentReel;
      let path = `/subscriber/${this.subscriberidLocal}/${this.profileid}/content/`;
      if ("seriesid" in content && content.seriesid) {
        path += `${content.objectid}/episodes/${content.objectid}`;
      } else {
        path += `${content.objectid}`;
      }
        firebase
        .database()
        .ref(path)
        .on("value", (snapshot) => {
          let response = snapshot.val();
          console.log("Short", response)
          if (!response) return;
          if (response && response.likestatus === "LIKE") {
            this.liked = true;
            this.action = "REMOVE";
            this.currentState = "LIKE";
          } else if (response && response.likestatus === "DISLIKE") {
            this.action = "ADD";
            this.liked = false;
            console.log("current state from like", response.likestatus);
            this.currentState = "DISLIKE";
          } else {
            this.liked = false;
            this.action = "ADD";
            this.currentState = "";
          }
        });
    },
    shareMovie(status) {
      this.isShareActive = status
    }, 
    actPlayback () {
      if (this.videoPlayer.paused) {
        this.videoPlayer.play();
      } else {
        this.videoPlayer.pause();
      }
    },
    getContentLikeCount(contentId) {
      // this.likeCountLoaded = false;
      let payload = {
        objectid: contentId,
        type: "like",
      };
      this.fetchLikeDislikeCount(payload).then((result) => {
        if (result) {
          this.contentLikeCount = result;
        } else {
          this.contentLikeCount = 0;
        }
        this.likeCountLoaded = true; 
      });
    },
        getContentDislikeCount(contentId) {
      // this.dislikeCountLoaded = false;
      let payload = {
        objectid: contentId,
        type: "dislike",
      };
      this.fetchLikeDislikeCount(payload).then((result) => {
        if (result) {
          this.dislikeCountDisplay = result;
        } else {
          this.dislikeCountDisplay = 0;
        }
        this.dislikeCountLoaded = true;
      });
    },
    getContentDetail(contentid) {
      let payload = {
        contentid: contentid,
        params: {
          displaylanguage: this.localDisplayLang,
        },
      };
      store
        .dispatch("contentDetail", payload)
        .then((response) => {
          if (!response.data.reason) {
            console.error(response.data)
            this.shortReelLists[0] = (response.data)
            this.fetchRelatedContents(contentid)
          } 
        })
        .catch((error) => console.log(error));
    },
    fetchRelatedContents(contentId) {
      let pageSize = 15;
      let pageNum = 1
      // let pageNum = Math.floor(this.episodeList.length / pageSize) + 1;

      let payload = {
        contentid: contentId,
        params: {
          displaylanguage: this.localDisplayLang,
          pagesize: pageSize,
          page: pageNum,
        },
      };
      this.getRelatedContents(payload)
    },
    getRelatedContents(payload) {
      store
      .dispatch("relatedContents", payload)
      .then((response) => {
        if (response.data) {
          this.shortReelLists = this.shortReelLists.concat(response.data.data);
          let reel = this.getTheStream(this.shortReelLists[this.currentReelIndex].contentdetails);
          // this.hls.loadSource(reel);
          // this.videoPlayer.play();
          this.playerInvocked(reel)
          this.currentReel = this.shortReelLists[this.currentReelIndex]
          this.getPoster(0);


          this.initFirebaseLike()

        }
      }).catch();
    },
    initFirebaseLike () {
    this.liked = null;

    this.getContentLikeCount(this.currentReel.objectid); 
    this.getContentDislikeCount(this.currentReel.objectid)
    this.fetchButtonState();
    this.fetchButtonStateForDisLike();
    },
    getTheStream(currentReel) {
      for (let item of currentReel) {
        if (item.streamtype == "HLS") {
          return item.streamfilename
        }
      }
    },
    checkKey(e) {
      e = e || window.event;

      if (e.keyCode == '38') {
          // up arrow
          if (this.currentReelIndex == 0) return

          let reel = this.getTheStream(this.shortReelLists[--this.currentReelIndex].contentdetails);
          this.getPoster();
          this.currentReel = this.shortReelLists[this.currentReelIndex]
          this.initFirebaseLike()

          // this.hls.loadSource(reel);
          // this.videoPlayer.play();
          this.playerInvocked(reel)
      } else if (e.keyCode == '40') {
          // down arrow
        if (this.shortReelLists.length - 1 == this.currentReelIndex) return;
        
        let reel = this.getTheStream(this.shortReelLists[++this.currentReelIndex].contentdetails);
      
        this.getPoster();
        this.currentReel = this.shortReelLists[this.currentReelIndex]
        // this.hls.loadSource(reel);
        // this.videoPlayer.play();
        this.playerInvocked(reel)
        this.initFirebaseLike()
      } else if (e.keyCode == '32' || e.keyCode == '13') {
        if (this.videoPlayer.paused) {
          this.videoPlayer.play();
        } else {
          this.videoPlayer.pause();
        }
        
      }
    },
    getPoster () {

      let poster = this.shortReelLists[this.currentReelIndex].poster[0];
      poster = poster.filelist;
      for (let item of poster) {
        if (item.quality == "HD") {
          this.getPosterFileurl = item.filename;
          break;
        }
      }

      let next = this.currentReelIndex + 1;
      if( this.shortReelLists.length - 1 == next) return;
      poster = this.shortReelLists[next].poster[0];
      poster = poster.filelist;
      for (let item of poster) {
        if (item.quality == "HD") {
          this.getPosterFileurlNext = item.filename;
          break;
        }
      }
    },
    closePopup () {
      this.isShareActive = false;

      setTimeout( () => {
        document.querySelector("body").style.overflow = "hidden";

      }, 100)

    },
    debounce(func, timeout = 300){
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
      };
    },
    throttle(fn, wait) {
      let throttled = false;
      return function(...args){
        if(!throttled){
            fn.apply(this,args);
            throttled = true;
            setTimeout(()=>{
                throttled = false;
            }, wait);
        }
    }
    },
    wheel (event) {
      if (event.deltaY < 0)
      {
        if (this.currentReelIndex == 0) return
          let reel = this.getTheStream(this.shortReelLists[--this.currentReelIndex].contentdetails);
          this.getPoster();
          
          this.currentReel = this.shortReelLists[this.currentReelIndex]
          this.initFirebaseLike()

          // this.hls.loadSource(reel);
          // this.videoPlayer.play();
          this.playerInvocked(reel)
      }
      else if (event.deltaY > 0)
      {
        if (this.shortReelLists.length - 1 == this.currentReelIndex) return;
        let reel = this.getTheStream(this.shortReelLists[++this.currentReelIndex].contentdetails);
        
        this.getPoster();
        this.currentReel = this.shortReelLists[this.currentReelIndex]
        // this.hls.loadSource(reel);
        // this.videoPlayer.play();
        this.playerInvocked(reel)
        this.initFirebaseLike()
      }
    },
    autoHideButtons () {
      this.autoHide = false;
    }

  },
  computed: {
    ...mapGetters([
      "subscriberid",
      "availabilityList",
      "subscriptionList",
      "purchaseList",
      "profileid",
      "getRtl",
      "getContinueWatchData",
    ]),
  },
  mounted() {
    this.dislikeCountDisplay = this.$t('dislike');
    this.dislikeCountDisplayNew = this.$t('dislike');

    this.contentLikeCount = 0;

    if (!this.subscriberidLocal) {
      this.subscriberidLocal = this.subscriberid;
      // If there is no user we have enable like, diLike button 
      this.likeCountLoaded = true;
    }
    this.wheel = this.throttle(this.wheel, 1000);
    this.updateLikes = this.throttle(this.updateLikes, 1000);
    this.autoHideButtons = this.debounce(this.autoHideButtons, 1000);
    this.isShareActive = false;
    this.initPlayerListener();
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    document.body.scrollTop = 0; 
    document.documentElement.scrollTop = 0;
    document.body.style.overflow = "hidden"
    this.getContentDetail(this.$route.params.contentId)

    this.videoPlayer.addEventListener('ended', () => {
 
      let reel = this.getTheStream(this.shortReelLists[++this.currentReelIndex].contentdetails);
        // this.hls.loadSource(reel);
        this.playerInvocked(reel)
        this.currentReel = this.shortReelLists[this.currentReelIndex]
        this.initFirebaseLike()

        // this.videoPlayer.play();
    })
    this.videoPlayer.addEventListener('progress', () => {
      console.log(1)
    })
    document.addEventListener('keydown', this.checkKey.bind(this))
    this.videoPlayer.addEventListener("pause", (event) => {
      this.isPaused = true;
      this.autoHide = true;

    });
    this.videoPlayer.addEventListener("play", (event) => {
      this.isPaused = false;
      this.autoHideButtons();
      this.autoHide = true;
    });
    window.addEventListener('wheel', this.wheel);


  },
  beforeRouteLeave (to, from , next) {
    this.videoPlayer.pause();
    this.hls && this.hls.destroy();

    setTimeout(() => {
      document.body.style.overflow = "scroll"
    }, 100);

    next()
  },
  components: {
   detailShare: () =>
      import(
        /* webpackChunkName: "detailshare" */ "@/components/Popups/contentShare.vue"
      ),
  },
}
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";

.shortpage-share{
  left: -19rem;
  width: 21rem;
   
}
.vlive-popup {
  overflow: inherit !important;
  background: none;
}
.poster-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  // margin-right: 6rem;
}
.poster {
  max-width: 80%;
  max-height: 70%;
}
.shorts-icon div {
  color: $clr-create-main-txt;
  margin-left: 0.4rem;
  margin-top: 0.4rem;

  font-size: 0.8rem;
  text-align: center;
  position: absolute;
}

.play-button {
    background-color: #343534;
    border: none;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
}

.play-button:hover {
  background: $clr-primary-button-common;
}

.button-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 3rem;
  flex-direction: column;
  position: absolute;
  top: 50%;
  right: -5rem;
  transform: translate(-50%, -50%);

}

.like-button,
.dislike-button,
.watchlist-button,
.share-button {
  background-color: #343534;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}
.like-button img,
.dislike-button img,
.watchlist-button img,
.share-button img {
  max-width: 100%;
  max-height: 100%;
}

.like-button:hover,
.dislike-button:hover,
.watchlist-button:hover,
.share-button:hover {
  background: $clr-primary-button-common;
}
.watch-now-container {
  display: flex;
  justify-content: space-between;
  p {
    width: 11rem;
    margin-left: 1rem;
    color: #FFFFFF;
    font-size: 0.8rem;
    position: absolute;
    left: 0%;
    bottom: 5%
  }
}

.active-action-short {
  background: $clr-primary-button-common;
}



.watch-now-button {
  background: $clr-primary-button-common;
  border: none;
  padding: 7px 16px;
  font-size: 10px;
  width: 6rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  position: absolute;
  right: 4%;
  bottom: 5%
}
.video-player-container {
  width: 45vh;
  // position: relative;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-65%, -55%);
}

@media only screen and (max-width: 576px){
  .video-player-container {
    width: 36vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-60%, -60%);
  }
   
  #video-short {
    height: 64vh !important;
    // margin-bottom: 60%;
    position: relative;
    // bottom: 4rem;
  
}
.play-button{
  position: absolute;
    right: 50%;
    top: 40%;
    transform: translate(50%, -50%);
}
.watch-now-container p {
  // bottom: 35%;
}
.watch-now-button{
  // bottom: 34% !important;
}
.button-container{
  top:40%
}

.poster-container{
  height: calc(100vh-110px) !important;
}
}
// @media only screen and (max-width: 700px) {
//   .video-player-container {
//     width: 36vh;
//     position: relative;
//     margin-left: -15%;
//   }

// }

#video-short {
  width: 100%;
  height: 80vh;
  background: black;
  border-radius: 15px;
}

.video-player-carousel img {
  width: 100%;
  display: none;
  position: absolute;
  padding-bottom: 12px;
  border-radius: 15px;
}

.nextcarouselimg {
  background: black;
  height: 100%;
}

@media only screen and (min-width: 1500px) {
  .poster-container {
    zoom: 0.75;
  }
}
</style>